

.grid-container
{
    display: grid;
    background-color: white;
    grid-template-columns: repeat(5, auto); 
    padding: 10px;
    color: #222;
  
   
}

.grid-cell {
    text-align: center;
    
}

.grid-cell > img{
   padding: 10px 10px;
   width: 120px;
   height: 120px;
   background-color: white;
   margin: 0 auto;
   object-fit: contain;
}


@media (max-width: 850px) {
    .grid-container {
        grid-template-columns: repeat(4, auto);
    }

    .grid-cell > img{
        padding: 10px 10px;
        width: 90px;
        height: 90px;
        background-color: white;
        margin: 0 auto;
        object-fit: contain;
     }
}


@media (max-width: 630px) {
    .grid-container {
        grid-template-columns: repeat(2, auto);
    }
}