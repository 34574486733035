

.col-container
{
    display: grid;
    background-color: rgb(242, 243, 234);
    grid-template-columns: 25% 25% 25%  25%; 
    padding: 50px 10% 50px 10%;
    color: #222;
}

.column {
   padding: 0px 8%; 
}

.column > p {
    padding: 0px ;
}

.icon  {
    font-size: 2.2rem;
    height: 60px;
    border: none;
    width: 100% !important;
    padding: 0;
    color: rgb(68, 67, 51);
    text-align-last: center;
}

.icon > p {
    padding: 0;
    
}


@media (max-width: 630px) {
    .column > p {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .column > p {
        font-size: 0.7em;
    }
}