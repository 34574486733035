.navi {
    background-color: var(--off-white);
    position: sticky;
    top: 0px;
    width: 100%;
    height: 65px;
    z-index: 1000;
    -webkit-transform: none;
    transform: none;
    transition: transform 0.2s;
    will-change: transform;
  }

  .navi-hidden {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    transition: transform 0.2s 0.3s;
    
  }
  
  h3.navi {
    cursor:  pointer;
  }
  
  .navi-logo {
    display: inline-block;
    height: 1.6em;
  }

  h5.navi-logo {
    margin: 0px;
  }
  
  .navi-logo:hover {
    cursor: pointer;
    
  }
  
  
  .navi .navi-content {
    padding: 0rem 3rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  ul.navi-items {
      margin: 0px;
  }
  
  .navi-item {
    display: inline;
    margin-left: 2rem;
    color: #666;
  }
  
  .navi-item:hover {
    color: black;
    cursor: pointer;
  }
  
  .navi-item > .active {
    border-bottom: 2px solid rgb(218, 185, 96);
  }


  .next-button {
    opacity: 0.7s;
    transform: rotateZ(0deg);
    transition: transform 0.3s;
  }

  .next-button:hover {
    opacity: 1.0;
  }

  .rotate {
    transform: rotateZ(180deg);
    transition: transform 0.3s;
  }



  @media (max-width: 750px) {
    .navi .navi-content {
      padding: 0rem 2rem;
    }

    h5.navi-logo {
      font-size: 16px;
    }

    .navi-item {
      font-size: 14px;
    }

    .next-button {
      display: none;
    }
  }

  @media (max-width: 620px) {
    .navi-item {
      margin-left: 1em;
      font-size: 11px;
    }
  }

  @media (max-width: 480px) {

    .navi-content {
      padding: 0rem 2rem;
    }

    ul.navi-items {
      padding-left: 1em;
    }
    h5.navi-logo {
      font-size: 4vw;
    }

    .navi-item {
      margin-left: 1em;
      font-size: 9px;
    }
  }

  @media (max-width: 400px) {

    .navi-content {
      padding: 0rem 2rem;
    }

    ul.navi-items {
      padding-left: 1em;
    }
    h5.navi-logo {
      font-size: 4vw;
    }

    .navi-item {
      margin-left: 1em;
      font-size: 9px;
    }
  }

  .hidden {
    display: none !important;
    margin: 0px;
    padding: 0px;
  }

  a {
      color: #aaa;
  }

  a:hover {
    
      color: #ccc;
  }