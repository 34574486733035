.footer {
    min-height: 300px;
    background-color: rgb(211, 210, 193);
   
}


.footer-container
{
    width: 70%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 50% 50%; 
    padding: 50px 0;
    color: #222;
    
}

.footer-column {
   padding: 0px 5px;
   margin: 0 auto;
  
}

.footer-column > p {
    padding: 0px ;
    font-size: 0.9rem;
    color: rgb(99, 98, 98);
}

.icon  {
    font-size: 2.2rem;
    height: 60px;
    border-bottom: 2px solid darkgray;
    width: 100%;
    padding: 0;
    color: rgb(68, 67, 51);
    text-align-last: center;
}

.icon > p {
    padding: 0;
    
}