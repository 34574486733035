.section-header {
    font-size: 62px;
    color: rgb(236, 229, 129);
}



@media (max-width: 700px) {
    .section-header {
        font-size: 48px;
    }
}

@media (max-width: 480px) {
    .section-header {
        font-size: 8vw;
    }
}